import type { UniversalTeaserQuery } from '../../../../graphqlTypes'
import { classNames } from '../../../../helpers/classNames'
import { useTracking, type DefaultEventData } from '../../../Shared/Hooks/TrackingHook'
import { asset } from '../../../Shared/asset'
import { AffiliateButton } from '../../Components/AffiliateButton/AffiliateButton'
import { Disclosure } from '../../Components/Disclosure/Disclosure.component'
import InformationIcon from '../../Images/InformationIcon.svg'

export interface UniversalTeaserProps {
    data: NonNullable<UniversalTeaserQuery['teaser']>
}

export type UniversalTeaserEventData = Pick<DefaultEventData, 'affiliate_elementid' | 'affiliate_type'>

export default function UniversalTeaser({ data }: UniversalTeaserProps) {
    const { trackingRef, trackClick } = useTracking<UniversalTeaserEventData>({
        event_name: 'affiliate',
        event_data: {
            affiliate_type: 'universalteaser',
            affiliate_elementid: data.id,
        },
    })

    const hasExternalLink =
        (data.buttonLink.href.startsWith('http') &&
            !/^https?:\/\/(www|gebrauchtwagen)(\.stage)?\.autobild\.de/.test(data.buttonLink.href)) ||
        (data.imageLink.href.startsWith('http') &&
            !/^https?:\/\/(www|gebrauchtwagen)(\.stage)?\.autobild\.de/.test(data.imageLink.href))

    return (
        <div
            ref={trackingRef}
            className={classNames(
                data.hasAdLabel ? 'font-slab' : 'font-sans',
                'text-20 leading-25 text-tarmac-500 md:mx-teaser-xl-aubi',
            )}
        >
            {data.hasAdLabel && (
                <div className="mb-4 text-right text-11 uppercase leading-12 text-tarmac-100">Anzeige</div>
            )}

            <div className="relative mb-24 border border-tarmac-75 p-12 pt-14 md:p-20">
                {data.title && (
                    <div
                        data-testid="universalTeaser-title"
                        className="absolute -top-14 left-5 bg-white px-5 text-18 font-bold uppercase leading-28 md:left-10 md:px-10"
                    >
                        {data.title}
                    </div>
                )}

                <div className="flex flex-col bg-tarmac-25 p-12 pb-14 text-center md:flex-row md:p-20 md:text-left">
                    <a
                        className="mb-6 flex flex-grow md:mb-none md:items-start"
                        href={data.imageLink.href}
                        target="_blank"
                        rel="noopener"
                        title="Zum Angebot"
                        onClick={() => {
                            trackClick({
                                event_data: {},
                            })
                        }}
                    >
                        <img
                            className="w-full md:w-[35rem] md:min-w-[35rem]"
                            data-testid="universalTeaser-image"
                            src={asset(data.image.src, { width: 350 })}
                            srcSet={`${asset(data.image.src, { width: 150 })} 150w, ${asset(data.image.src, {
                                width: 250,
                            })} 250w, ${asset(data.image.src, { width: 350 })} 350w, ${asset(data.image.src, {
                                width: 400,
                            })} 400w, ${asset(data.image.src, { width: 500 })} 500w, ${asset(data.image.src, {
                                width: 600,
                            })} 600w, ${asset(data.image.src, { width: 678 })} 678w`}
                            sizes="(max-width: 239px) 150px, (max-width: 339px) 250px, (max-width: 439px) 350px, (max-width: 489px) 400px, (max-width: 589px) 500px, (max-width: 689px) 600px, (max-width: 768px) 678px, 350px"
                            data-copyright-text={data.image.copyright}
                            width={data.image.width}
                            height={data.image.height}
                            alt={data.image.altText}
                            loading="lazy"
                        />
                    </a>

                    <div className="md:ml-15 md:flex md:flex-grow md:flex-col">
                        <div className="mb-10 md:mb-10 md:flex md:flex-grow md:flex-col">
                            <p
                                data-testid="universalTeaser-headline"
                                className="text-18 font-bold leading-26 text-tarmac-500 md:mb-5 md:text-24 md:leading-32"
                            >
                                {data.headline}
                            </p>
                            <p
                                data-testid="universalTeaser-text"
                                className="text-18 leading-26 text-tarmac-500 md:flex-grow md:text-20"
                            >
                                {data.description}
                            </p>
                        </div>

                        <div
                            className={classNames(
                                'items-center justify-between md:flex md:items-end',
                                !data.logo && 'md:justify-end',
                            )}
                        >
                            <div className="flex-1 md:order-2 md:grow-0 md:justify-center md:text-right">
                                <div className="inline-block w-full md:w-[26.6rem]">
                                    {hasExternalLink && <OffersListHeadline hasAdLabel={data.hasAdLabel} />}
                                    <AffiliateButton
                                        className={classNames(
                                            data.hasAdLabel ? 'font-slab' : 'font-condensed',
                                            'inline-flex w-full whitespace-nowrap',
                                        )}
                                        text={data.buttonLink.text}
                                        url={data.buttonLink.href}
                                        onClick={() => {
                                            trackClick({
                                                event_data: {},
                                            })
                                        }}
                                    />
                                </div>
                            </div>

                            {data.logo && (
                                <div
                                    data-testid="universalTeaser-partner"
                                    className="mt-20 flex flex-row items-center justify-end text-12 leading-28 text-tarmac-200 md:order-1 md:mr-5 md:mt-none"
                                >
                                    <p className="mr-5">In Kooperation mit</p>
                                    <img
                                        className="max-h-25 w-auto md:max-h-30"
                                        data-copyright-text={data.logo.copyright}
                                        width={data.logo.width}
                                        height={data.logo.height}
                                        alt={data.logo.altText}
                                        src={asset(data.logo.src, { width: 200 })}
                                        loading="lazy"
                                    />
                                </div>
                            )}
                        </div>
                    </div>
                </div>
            </div>
        </div>
    )
}

function OffersListHeadline({ hasAdLabel }: { hasAdLabel: boolean }) {
    return (
        <div className="mb-6 flex items-center">
            <div className="h-px flex-grow bg-raven-100" />
            <Disclosure>
                <div className="group flex items-center">
                    <span
                        className={classNames(
                            'ml-12 flex flex-shrink items-center justify-center text-16 font-bold uppercase text-tarmac-500 focus:outline-none',
                            hasAdLabel ? 'font-slab' : 'font-sans',
                        )}
                    >
                        Zum Angebot
                    </span>
                    <div className="ml-10 mr-10 flex h-20 w-20 justify-center rounded-full bg-tarmac-100 group-hover:bg-red-light-500 md:mr-9">
                        <img
                            src={asset(InformationIcon)}
                            alt="Buchstabe i in einem Kreis"
                            loading="lazy"
                            width="4"
                            height="12"
                        />
                    </div>
                </div>
            </Disclosure>
            <div className="ml-2 h-px flex-grow bg-raven-100 md:ml-3" />
        </div>
    )
}
